<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
    :transition="
      $vuetify.breakpoint.xs ? 'dialog-bottom-transition' : 'dialog-transition'
    "
    max-width="750"
    persistent
  >
    <v-card>
      <v-card-title class="primary" style="padding-top: 10px">
        <span class="white--text">ส่งออกข้อมูล</span>
        <v-btn
          icon
          class="ml-auto white--text"
          style="margin-right: -8px"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-6">
        <v-form
          v-model="isFormValid"
          ref="form"
          lazy-validation
          class="pt-0"
          enctype="multipart/form-data"
        >
          <v-row align="center">
            <v-col sm="6" cols="12">
              <v-row align="center">
                <v-col cols="6">หมายเลขทางหลวง</v-col>
                <v-col cols="6">
                  <v-select
                    v-model="exportForm.road_id"
                    :items="itemLists.road"
                    placeholder="เลือก"
                    item-text="label"
                    item-value="road_id"
                    outlined
                    dense
                    hide-details
                    clearable
                    no-data-text="ไม่มีหมายเลขทางหลวงให้เลือก"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="6" cols="12">
              <v-row align="center">
                <v-col cols="6">ตอนควบคุม</v-col>
                <v-col cols="6">
                  <v-select
                    v-model="exportForm.section_part_id"
                    :items="itemLists.section_part"
                    placeholder="เลือก"
                    item-text="label"
                    item-value="section_part_id"
                    outlined
                    dense
                    hide-details
                    no-data-text="โปรดเลือกหมายเลขทางหลวง"
                    :loading="isLoadingSectionPart"
                    clearable
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row align="center">
                <v-col sm="3">สะพาน</v-col>
                <v-col sm="9">
                  <v-autocomplete
                    v-model="exportForm.bridge_id"
                    :items="bridgeListBySectionPart"
                    placeholder="เลือก"
                    item-text="name"
                    outlined
                    dense
                    no-data-text="ไม่มีสะพานให้เลือก"
                    hide-details
                    return-object
                    :loading="isLoadingBridge"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col sm="3">รายงาน</v-col>
                <v-col sm="9">
                  <v-radio-group
                    v-model="exportForm.option"
                    class="mt-0"
                    hide-details
                  >
                    <v-radio
                      v-for="report in reportList"
                      :key="report.value"
                      :label="report.name"
                      :value="report.value"
                      hide-details
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="0" @click="clearFilter">
          <v-icon left>mdi-close</v-icon>
          ล้างข้อมูล
        </v-btn>
        <v-btn
          :disabled="!(exportForm.option && exportForm.bridge_id)"
          color="success"
          elevation="0"
          style="width: 140px"
          :href="getReport()"
          target="_blank"
        >
          ดำเนินการ
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import utils from '@/assets/js/utils'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        this.dialog = newVal
      }
    },
    'exportForm.road_id' (newVal, oldVal) {
      if (newVal) {
        this.getSectionPart(newVal)
      } else {
        delete this.exportForm.section_part_id
        this.itemLists.section_part = []
      }
    },
    bridgeListBySectionPart (newVal, oldVal) {
      if (this.exportForm.bridge_id) {
        const checkid = newVal.some(
          (id) => id.id === this.exportForm.bridge_id.id
        )
        if (!checkid) {
          delete this.exportForm.bridge_id
        }
      }
    }
  },
  data: () => ({
    dialog: true,
    province: [],
    filterContainer: [],
    itemLists: {
      road: [],
      bridgeType: [],
      province: [],
      bridgeList: []
    },
    name: null,
    isFormValid: false,
    isLoadingSectionPart: false,
    exportForm: {},
    getKm: utils.getKm,
    reportList: [
      { name: 'S.R.', value: 'bridge_SR01' },
      { name: 'Book Report', value: 'book_bridge' }
    ],
    isLoadingBridge: true
  }),
  mounted () {
    this.getBridgeList()
    this.getRefRoad()
  },
  computed: {
    bridgeListBySectionPart () {
      if (
        this.exportForm.road_id &&
        this.exportForm.section_part_id &&
        this.itemLists.bridgeList.length
      ) {
        const bridgeList = this.itemLists.bridgeList.filter(
          (bridge) =>
            bridge.section_part_id === parseInt(this.exportForm.section_part_id)
        )
        return bridgeList
      } else if (this.itemLists.bridgeList.length) {
        return this.itemLists.bridgeList
      } else return []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    clearFilter () {
      this.exportForm = {}
    },
    doSearch () {
      if (this.$refs.form.validate()) {
        // this.$emit('close', this.filterForm)
        this.getReport()
      } else {
        const invalidField = this.$refs.form.inputs.find((e) => !e.valid)
        if (invalidField) {
          invalidField.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
      // this.filter = {}
    },
    async getRefRoad () {
      const roads = await this.$store.dispatch('ref/getRef', {
        method: 'v-mbmms-road-exist-only'
      })
      for (const road of roads) {
        road.label = `${parseInt(road.code)}`
      }
      const checkid = this.exportForm.route
        ? roads.some((id) => id.road_id === this.exportForm.route.road_id)
        : false
      if (!checkid) {
        delete this.exportForm.route
        delete this.exportForm.control
        this.itemLists.control = []
      }
      this.itemLists.road = roads
    },
    async getBridgeList () {
      const bridgeList = await this.$store.dispatch('getBridgeList', {})
      this.itemLists.bridgeList = []
      bridgeList.forEach((section) => {
        this.itemLists.bridgeList.push({
          header: `ทางหลวงหมายเลข ${parseInt(section.code_road)} - ${
            section.name
          }`,
          section_part_id: section.section_part_id
        })
        this.itemLists.bridgeList.push(
          ...section.items.map((b) => ({
            ...b,
            name: `[${this.getKm(b.km)}] ${b.name} ${
              b.direction ? `(${b.direction})` : ''
            }`,
            section_part_id: section.section_part_id,
            road_id: section.road_id
          }))
        )
      })
      this.isLoadingBridge = false
    },
    async getSectionPart (roadid) {
      this.isLoadingSectionPart = true
      const controls = await this.$store.dispatch('ref/getRef', {
        method: `v-mbmms-section-part-exist-only?filters=road_id:${roadid}`
      })
      for (const control of controls) {
        control.label = `${control.code} - ${control.name}`
      }
      this.itemLists.section_part = controls
      const checkSection = controls.find(
        (control) => control.section_part_id === this.exportForm.section_part_id
      )
      if (!checkSection) {
        this.exportForm.section_part_id = null
        delete this.exportForm.section_part_id
      }
      this.isLoadingSectionPart = false
    },
    async getRef (method) {
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
    },
    async getRefFilterSr () {
      this.itemLists.sr = await this.$store.dispatch('getRefFilterSr')
    },
    filterkm (event) {
      event = event || window.event
      const expect = event.target.value.toString() + event.key.toString()
      if (
        !/^[0-9]*$/.test(expect) &&
        !/^[0-9]+[+]$/.test(expect) &&
        !/^[0-9]+[+][0-9]*$/.test(expect)
      ) {
        event.preventDefault()
      } else {
        return true
      }
    },
    getReport () {
      let link = ''
      if (this.exportForm.option && this.exportForm.bridge_id) {
        link = `${process.env.VUE_APP_REPORT}/Reports/M_BMMS_reports/${
          this.exportForm.option === 'book_bridge' &&
          this.exportForm.bridge_id.structure.id === '1'
            ? 'book_culvert'
            : this.exportForm.option
        }.pdf?bridge_id=${
          this.exportForm.bridge_id ? this.exportForm.bridge_id.id : ''
        }`
      }
      return link
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
