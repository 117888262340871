<template>
  <div class="fill-height">
    <LegendMap
      v-if="$vuetify.breakpoint.smAndUp || showMap"
      id="legendMap"
      :srList="itemLists.sr"
      :legendItems="legendItems"
    />
    <v-container class="fill-height ma-0 pa-0" fluid>
      <v-layout class="split">
        <v-col
          v-if="!showMap || $vuetify.breakpoint.smAndUp"
          ref="leftPanel"
          class="pa-0 overflow-y-hidden theme-bg"
          sm="7"
        >
          <div>
            <div class="d-flex pt-4 pb-3 px-4 align-center">
              <h2 class="primary--text font-weight-regular">
                เลือกสะพานที่ต้องการตรวจสอบ
              </h2>
              <v-btn
                color="primary"
                class="ml-auto"
                @click="exportBridgeDamageDialog = !exportBridgeDamageDialog"
              >
                <v-icon left>mdi-export-variant</v-icon>
                ส่งออก
              </v-btn>
            </div>
            <div class="d-flex align-center px-4 mb-2">
              <SearchBridgeField
                ref="searchBridge"
                :loading="loading"
                :loadCord="loadCord"
                @input="inputSuggest"
              />
              <v-btn
                class="primary elevation-2 white--text rounded ml-2"
                icon
                tile
                @click="filterSearchDialog = !filterSearchDialog"
              >
                <v-icon>mdi-filter-variant</v-icon>
              </v-btn>
            </div>
            <div class="px-4 text-caption grey--text">
              ผลการค้นหาทั้งหมด {{ sumBridge }} สะพาน
            </div>
            <v-virtual-scroll
              ref="virtualScroller"
              :height="virtualScrollerHeight"
              :item-height="$vuetify.breakpoint.xs ? 94 : 84"
              :items="bridgeData"
              :class="{
                'mr-sm-4': !virtualScrollOverflown,
                'mr-sm-0': virtualScrollOverflown
              }"
              bench="2"
              class="mt-2 ml-4 mr-4 pb-4"
            >
              <template v-slot:default="{ item }">
                <DamageListItem
                  :key="item.id"
                  :data-id="item.id"
                  :id="item.id"
                  :bridge_length="item.bridge_length"
                  :code_road="item.code_road"
                  :direction="item.direction"
                  :distance="item.distance || item.kms"
                  :image="item.img"
                  :km="item.km"
                  :name="item.name"
                  :sr="item.sr"
                  :bridge_category="item.bridge_category"
                  class="mb-3 pointer-hover light-blue-hover"
                  :selectedId="selectedId"
                  @click="viewDamageDetail(item)"
                  @clickLabel="goToLocation(item)"
                />
              </template>
            </v-virtual-scroll>
          </div>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndUp || showMap"
          class="right ma-0 pa-0"
        >
          <longdo-map ref="ld_map" @load="loadMap">
            <longdo-map-marker-cluster
              :markerList="bridgeList"
              :minClusterSize="3"
              :styles="styleCluster"
              @add="markerAdded"
            />
            <!-- <longdo-map-marker
              v-for="bridge in bridgeData"
              :key="bridge.id"
              :detail="markerDetail(bridge)"
              :location="markerLocation(bridge)"
              :title="markerTitle(bridge)"
              :icon="{
                html: `
                  <div class='d-flex justify-center align-center' style='
                      background-color: ${getColorIcon(bridge.sr)};
                      border: 3px solid white;
                      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                      width: ${iconSize + 5}px;
                      height: ${iconSize + 5}px;
                      border-radius: 50%;
                  '>
                        ${checkBridgeType(bridge['nbes_42.2'])}
                  </div>
                `,
                offset: {
                  x: parseInt((iconSize + 8) / 2),
                  y: parseInt((iconSize + 8) / 2)
                }
              }"
              @add="markerAdded($event, bridge.id)"
            /> -->
          </longdo-map>
        </v-col>
      </v-layout>
    </v-container>
    <v-btn
      bottom
      class="white--text hidden-sm-and-up blue mb-6"
      fab
      fixed
      right
      @click="showMap = !showMap"
    >
      <v-icon v-if="showMap">mdi-menu</v-icon>
      <v-icon v-else>mdi-map-outline</v-icon>
    </v-btn>
    <FilterSearchDialog v-model="filterSearchDialog" @close="filterSearch" />
    <ExportBridgeDamageDialog
      v-model="exportBridgeDamageDialog"
      @close="exportBridgeDamageDialog = false"
    />
  </div>
</template>

<script>
import SearchBridgeField from '@/components/SearchBridgeField.vue'
import utils from '@/assets/js/utils'
import LegendMap from '@/components/LegendMap.vue'
import ExportBridgeDamageDialog from '@/components/damage/dialog/ExportBridgeDamageDialog.vue'

export default {
  components: {
    DamageListItem: () => import('../components/DamageListItem.vue'),
    FilterSearchDialog: () =>
      import('../components/dialog/FilterSeachDialog.vue'),
    SearchBridgeField,
    LegendMap,
    ExportBridgeDamageDialog
  },
  data: () => ({
    getKm: utils.getKm,
    map: null,
    loading: false,
    isOverflown: utils.isOverflown,
    // --
    bridgeData: [],
    advanceSearch: false,
    showMap: false,
    keyword: '',
    // --
    control: null,
    road: '',
    km_start: '',
    km_end: '',
    type: '',
    filter: {
      control: null,
      road: '',
      km_start: '',
      km_end: '',
      type: ''
    },
    // --
    roadList: [],
    controlList: [],
    bridgeTypeList: [],
    // --
    virtualScrollerHeight: 300,
    virtualScrollOverflown: false,
    // --
    filterSearchDialog: false,
    loadCord: false,
    userPosition: undefined,

    search: '',
    bridgeSuggest: [],
    isSuggestLoading: false,

    colors: ['#F2453D', '#FACE44', '#93C13B', '#A9A9A9'],

    iconSize: 30,
    legendItems: [
      {
        name: 'Emergency Inspection',
        icon: 'mdi-circle-small',
        color: '#F2453D',
        inspection: true
      },
      {
        name: 'In-depth Inspection',
        icon: 'mdi-circle-small',
        color: '#FACE44',
        inspection: true
      },
      {
        name: 'Routine Inspection',
        icon: 'mdi-circle-small',
        color: '#93C13B',
        inspection: true
      },
      {
        name: 'ยังไม่มีการตรวจสอบ',
        icon: 'mdi-circle-small',
        color: '#A9A9A9',
        inspection: true
      }
    ],
    itemLists: {
      sr: []
    },
    srData: [],
    selectedId: null,
    clusterMap: {},
    styleCluster: [
      {
        url: require('@/assets/img/ic_cluster_3.png'),
        width: 45,
        height: 45,
        minThreshold: 1,
        color: 'white'
      }
    ],
    exportBridgeDamageDialog: false
  }),
  watch: {
    advanceSearch () {
      setTimeout(this.computeVirtualScrollerHeight, 10)
    },
    'filter.road' (newVal) {
      if (newVal) {
        this.control = null
        this.getSectionPart(newVal)
      } else {
        this.control = null
        this.controlList = []
      }
    },
    map (to, from) {
      if (to && !from) {
        this.boundMap()
      }
    },
    'bridgeData.length' () {
      this.boundMap()
    },
    'clusterMap._markers' (newVal, oldVal) {
      newVal.forEach((marker, markerindex) => {
        marker.bridge = true
        marker.bridgeId = this.bridgeList[markerindex].id
        const detailDom = marker.popup().detail()
        const anchor = detailDom.querySelector('[data-ref="router-link"]')
        anchor.addEventListener('click', (e) => {
          e.preventDefault()
          this.$router.push({
            path: '/damageDetail',
            query: { id: anchor.dataset.id }
          })
        })
        anchor.href = `/damageDetail?id=${anchor.dataset.id}`
      })
    }
  },
  computed: {
    icon () {
      return {
        crossBridge: `<svg width='${this.iconSize}' height='${this.iconSize}' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M20.3297 13.2378C20.0745 13.2842 19.9915 13.1309 19.9206 12.9191C19.7971 12.546 19.6574 12.179 19.5136 11.82C19.4326 11.6163 19.4346 11.5194 19.6331 11.5194C20.842 11.5235 22.051 11.5235 23.262 11.5194C23.4321 11.5194 23.503 11.6042 23.4989 11.8321C23.4908 12.185 23.4868 12.54 23.4989 12.8929C23.5111 13.1712 23.4179 13.2479 23.2235 13.2681C21.7817 13.4173 20.6436 14.7605 20.358 16.6402C20.3317 16.8258 20.3155 17.0113 20.3094 17.1989C20.3013 17.4248 20.2102 17.5014 20.0502 17.4974C19.7748 17.4913 19.4994 17.4913 19.224 17.4974C19.0336 17.5014 18.9648 17.3965 18.9567 17.1424C18.8797 14.6193 18.1548 12.4835 16.7757 10.7329C14.2828 7.57045 10.2995 7.35667 7.61626 10.2549C5.97392 12.0297 5.11529 14.339 5.02011 17.1424C5.01201 17.3925 4.95531 17.5075 4.7609 17.4994C4.50371 17.4893 4.2445 17.4873 3.98529 17.4994C3.78076 17.5095 3.71393 17.3925 3.69773 17.1323C3.59243 15.3293 2.70545 13.8892 1.4094 13.3991C1.19676 13.3144 0.971978 13.2681 0.74517 13.262C0.583163 13.26 0.496085 13.1733 0.500135 12.9413C0.504185 12.5662 0.500135 12.1911 0.500135 11.8159C0.500135 11.6122 0.560888 11.5154 0.724919 11.5174C1.93389 11.5215 3.14286 11.5215 4.35386 11.5174C4.52802 11.5174 4.55434 11.5961 4.47334 11.7978C4.32146 12.179 4.17768 12.5642 4.04402 12.9575C3.97922 13.1491 3.90024 13.2761 3.68558 13.2217C3.81519 13.4153 3.92252 13.5504 4.0096 13.7077C4.12705 13.9235 4.17768 13.8408 4.23033 13.6492C4.43891 12.8748 4.7285 12.1265 5.09301 11.4126C6.59157 8.53451 8.64905 6.88069 11.2837 6.54791C14.9065 6.09009 18.3613 8.952 19.6958 13.4758C19.7904 13.7971 19.9247 13.8301 20.0988 13.5746C20.1596 13.4859 20.2203 13.3971 20.3297 13.2378V13.2378Z' fill='white'/>
                      <path d='M6.64634 13.6031C6.56331 13.6051 6.48029 13.6011 6.39726 13.591C6.28588 13.5748 6.25145 13.478 6.30815 13.3207C6.51471 12.7439 6.76987 12.1852 7.07161 11.6528C7.14856 11.5217 7.24982 11.5237 7.35107 11.5237H16.6583C16.7231 11.5177 16.79 11.5338 16.8467 11.5681C16.9034 11.6024 16.9479 11.6528 16.9742 11.7133C17.2375 12.1994 17.4684 12.7015 17.6668 13.2179C17.7701 13.4901 17.7195 13.6051 17.5311 13.6051C17.3145 13.6051 17.0978 13.6091 16.8811 13.6091C15.2468 13.6091 8.42436 13.6071 6.64837 13.6071L6.64634 13.6031Z' fill='white'/>
                      <path d='M18.0129 14.5551C17.9846 14.42 17.8631 14.3252 17.7254 14.3252H6.27556C6.13786 14.3252 6.01838 14.42 5.988 14.5551C5.75512 15.6301 5.62754 16.6688 5.57489 17.177C5.55666 17.3505 5.69234 17.4997 5.86852 17.4997H8.07991C8.78463 16.0476 10.271 15.0452 11.9964 15.0452C13.7218 15.0452 15.2062 16.0456 15.9109 17.4916H18.1364C18.3106 17.4916 18.4463 17.3404 18.4281 17.169C18.3734 16.6627 18.2478 15.6281 18.017 14.5571L18.0129 14.5551Z' fill='white'/>
                    </svg>`,
        interChange: `<svg width='${this.iconSize}' height='${this.iconSize}' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M19.9919 9.71787C18.2942 9.58916 16.9501 9.07192 15.9884 8.17524C14.4419 6.73262 14.2927 4.67705 14.2816 4.13541V4H12.3309V9.43078H14.2816V7.00918C14.5782 7.6075 14.9796 8.1478 15.4668 8.60444C15.9719 9.0731 16.5548 9.45006 17.1894 9.71835H6.8063C7.4409 9.45006 8.02382 9.0731 8.52884 8.60444C9.01608 8.1478 9.4175 7.6075 9.71405 7.00918V9.43078H11.6648V4H9.71787V4.13541C9.70687 4.67753 9.55758 6.7331 8.01112 8.17524C7.04985 9.07192 5.70579 9.58916 4.00766 9.71787H4V11.6701H20V9.71787H19.9919Z' fill='white'/>
                      <path d='M4 14.2823H4.00766C5.70531 14.411 7.04985 14.9283 8.01112 15.8249C9.55758 17.2676 9.70687 19.3231 9.71787 19.8648V20.0002H11.6687V14.5694H9.71787V16.991C9.42133 16.3927 9.01991 15.8524 8.53267 15.3957C8.02765 14.9271 7.44473 14.5501 6.81013 14.2818H17.1932C16.5586 14.5501 15.9757 14.9271 15.4707 15.3957C14.9834 15.8524 14.582 16.3927 14.2855 16.991V14.5694H12.3309V20.0002H14.2816V19.8648C14.2931 19.3222 14.4419 17.2671 15.9884 15.8249C16.9501 14.9283 18.2942 14.411 19.9919 14.2823H19.9995V12.3311H4V14.2823Z' fill='white'/>
                    </svg>`,
        normal: `<svg width='${this.iconSize}' height='${this.iconSize}' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M7 14V10.91C6.28 10.58 5.61 10.18 5 9.71V14H7ZM5 18H3V16H1V14H3V7H5V8.43C6.8 10 9.27 11 12 11C14.73 11 17.2 10 19 8.43V7H21V14H23V16H21V18H19V16H5V18ZM17 10.91V14H19V9.71C18.39 10.18 17.72 10.58 17 10.91ZM16 14V11.32C15.36 11.55 14.69 11.72 14 11.84V14H16ZM13 14V11.96L12 12L11 11.96V14H13ZM10 14V11.84C9.31 11.72 8.64 11.55 8 11.32V14H10Z' fill='white'/>
              </svg>
              `,
        overpass: `<svg width='${this.iconSize}' height='${this.iconSize}' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00684 19.049H10.9498V13.799H6.74984L5.00684 19.049Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0498 13.799V19.049H18.9928L17.2498 13.799H13.0498Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 10.649V11.699V12.749H3.6V13.799H5.7V12.749H18.3L18.363 13.799H20.4V12.749H22.5V11.699V10.649H1.5Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.95 5H9.67948L8.14648 9.599H10.95V5Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3203 5H13.0498V9.599H15.8533L14.3203 5Z" fill="white"/>
          </svg>
          `
      }
    },
    sumBridge () {
      return this.bridgeData.length
    },
    bridgeList () {
      const bridgeList = []
      for (const bridge of this.bridgeData) {
        bridgeList.push({
          ...bridge,
          location: this.markerLocation(bridge),
          options: {
            title: this.markerTitle(bridge),
            detail: this.markerDetail(bridge),
            icon: {
              html: `
                        <div class='d-flex justify-center align-center' style='
                            background-color: ${this.getColorIcon(bridge.sr)};
                            border: 3px solid white;
                            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                            width: ${this.iconSize + 5}px;
                            height: ${this.iconSize + 5}px;
                            border-radius: 50%;
                        '>
                              ${this.checkBridgeType(bridge.bridge_category.id)}
                        </div>
                      `,
              offset: {
                x: parseInt((this.iconSize + 8) / 2),
                y: parseInt((this.iconSize + 8) / 2)
              }
            }
          }
        })
        // for (const bridge of section.items) {
        // bridgeList.push({
        //   ...bridge,
        //   section_part_id: section.section_part_id,
        //   location: this.markerLocation(bridge),
        //   options: {
        //     title: this.getHeaderName(bridge),
        //     detail: this.markerDetail(bridge),
        //     icon: {
        //       html: `
        //               <div class='d-flex justify-center align-center' style='
        //                   background-color: ${this.getColorIcon(bridge.sr)};
        //                   border: 3px solid white;
        //                   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        //                   width: ${this.iconSize + 5}px;
        //                   height: ${this.iconSize + 5}px;
        //                   border-radius: 50%;
        //               '>
        //                     ${this.checkBridgeType(bridge['nbes_42.2'])}
        //               </div>
        //             `,
        //       offset: {
        //         x: parseInt((this.iconSize + 8) / 2),
        //         y: parseInt((this.iconSize + 8) / 2)
        //       }
        //     }
        //   }
        // })
        // }
      }
      return bridgeList
    }
  },
  beforeMount () {
    if (this.$route.params.keyword) {
      this.keyword = this.$route.params.keyword
      this.search = this.$route.params.keyword
    }
  },
  async mounted () {
    this.getRefFilterSr()
    this.getSettingSr()
    this.getRefBridgeCategory()
    this.$store.dispatch('getRefRoad', {}).then((data) => {
      this.roadList = data
    })
    this.$store
      .dispatch('ref/getRef', { method: 'bridge-type' })
      .then((data) => {
        this.bridgeTypeList = data
      })
    // --
    const hasBeenGrantGeolocation = await this.$store.dispatch(
      'geolocationStatus'
    )
    if (hasBeenGrantGeolocation) {
      this.loadCord = true
      this.getUserLocation(this.getBridgeList)
    } else {
      await this.getBridgeList()
      this.getUserLocation(this.getBridgeList)
    }
    // --
    window.addEventListener('resize', this.computeVirtualScrollerHeight)
  },
  methods: {
    getUserLocation (callback) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude
            const lon = position.coords.longitude
            this.userPosition = { lat, lon }
            this.loadCord = false
            // eslint-disable-next-line standard/no-callback-literal
            callback(this.userPosition)
          },
          (err) => {
            console.warn(err)
            callback()
          },
          {
            enableHighAccuracy: false,
            timeout: Infinity,
            maximumAge: 2 * 60 * 1000 // 2 min cache
          }
        )
      } else {
        callback()
      }
    },
    loadMap (map) {
      this.map = map
      map.Event.bind('overlayClick', (overlay) => {
        this.selectedId = overlay.bridgeId
        if (overlay.bridge) {
          const index = this.bridgeList.findIndex(
            (item) => item.id === overlay.bridgeId
          )
          if (this.map.zoom() !== 20) {
            this.map.zoom(20)
            setTimeout(() => {
              this.map.location(this.bridgeList[index].location, true)
              this.map.resize()
            }, 500)
          }
          // setTimeout(() => {
          //   const position = utils.getLocationFromPointString(
          //     this.bridgeData[index].geom
          //   )
          //   this.map.location(position, true)
          //   this.map.zoom(20, true)
          //   this.map.resize()
          // }, 500)
          if (index > 0 && this.$refs.virtualScroller) {
            const eachComponentHeight = this.$vuetify.breakpoint.xs ? 94 : 84
            const el = this.$refs.virtualScroller.$el
            el.scrollTo({
              top: eachComponentHeight * index,
              behavior: 'smooth'
            })
          }
        }
      })
      setTimeout(this.map.resize, 200)
    },
    viewDamageDetail (item) {
      this.$router.push({ path: '/damageDetail', query: { id: item.id } })
    },
    goToLocation (item) {
      this.selectedId = item.id
      if (this.$vuetify.breakpoint.smAndUp) {
        if (this.map.zoom() !== 20) {
          this.map.zoom(20, true)
        }
        // --
        const position = utils.getLocationFromPointString(item.geom)
        this.map.location(position, true)
        setTimeout(() => {
          const overlays = this.map.Overlays.list()
          console.log(overlays)
          overlays.forEach((overlay) => {
            if (overlay instanceof window.longdo.Popup) {
              this.map.Overlays.remove(overlay)
            }
            if (overlay.bridgeId === item.id) {
              overlay.pop(true)
            }
          })
        }, 1000)

        this.map.resize()
      }
    },
    computeVirtualScrollerHeight () {
      const parent = this.$refs.leftPanel
      if (!parent) return false
      const parentHeight = parent.offsetHeight
      let scrollerHeight = parentHeight - 64 - 60
      if (this.$refs.advanceSearch) {
        const advanceSearch = this.$refs.advanceSearch
        scrollerHeight = scrollerHeight - advanceSearch.$el.offsetHeight - 8
      }
      this.virtualScrollerHeight = scrollerHeight
      this.$nextTick(() => {
        const el = this.$refs.virtualScroller.$el
        const scrollbarWidth = el.offsetWidth - el.clientWidth
        const isTouchAgent = scrollbarWidth <= 0
        this.virtualScrollOverflown = this.isOverflown(el) && !isTouchAgent
      })
    },
    markerLocation (item) {
      const geom = String(item.geom)
      return utils.getLocationFromPointString(geom)
    },
    markerTitle (bridge) {
      return `
        <div class="v-application">
            ${bridge.name} ${bridge.direction ? `(${bridge.direction})` : ''}
        </div>
      `
    },
    markerDetail (item) {
      return `
        <div class="v-application">
          <table style="font-size: 13px; table-layout: fixed;">
            <tr>
              <td style="padding: 1px 8px;">km</td>
              <td style="padding: 1px 8px;">${this.getKm(item.km)}</td>
            </tr>
            <tr>
              <td style="padding: 1px 8px; min-width: 90px;">ความยาวสะพาน</td>
              <td style="padding: 1px 8px;">${item.bridge_length}</td>
            </tr>
            <tr>
              <td style="padding: 1px 8px;">ประเภทสะพาน</td>
              <td style="padding: 1px 8px;">${item.structure.name}</td>
            </tr>
            <tr>
              <td style="padding: 1px 8px;">ค่า S.R.</td>
              <td style="padding: 1px 8px;">${
                item.sr ? parseFloat(item.sr).toFixed(2) : '-'
              }</td>
            </tr>
            <tr>
              <td colspan="2" style="padding: 1px 8px; min-width: 90px;">
                <a class="primary--text" data-ref="router-link" data-id="${
                  item.id
                }">
                  รายละเอียด
                </a>
              </td>
            </tr>
          </table>
        </div>
      `
    },
    markerAdded (e) {
      this.clusterMap = e
      // obj.bridge = true
      // obj.bridgeId = id
      // const detailDom = obj.popup().detail()
      // const anchor = detailDom.querySelector('[data-ref="router-link"]')
      // anchor.addEventListener('click', (e) => {
      //   e.preventDefault()
      //   this.$router.push({
      //     path: '/damageDetail',
      //     query: { id: anchor.dataset.id }
      //   })
      // })
      // anchor.href = `/damageDetail?id=${anchor.dataset.id}`
    },
    filterSearch (filter) {
      this.filterSearchDialog = false
      if (filter) {
        this.filter = { ...filter }
        if (this.filter.km_start) {
          this.filter.km_start = this.filter.km_start.replace('+', '')
        }
        if (this.filter.km_end) {
          this.filter.km_end = this.filter.km_end.replace('+', '')
        }
        this.getBridgeList()
      }
    },
    /** @param { {lat, lon} | undefined } position  */
    async getBridgeList (position = this.userPosition) {
      this.loading = true
      this.bridgeData = []
      const keyword = this.keyword
        ? this.keyword.name
          ? this.keyword.name
          : this.keyword
        : ''
      this.bridgeData = await this.$store.dispatch('getBridgeDamageList', {
        keyword,
        ...this.filter,
        ...position
      })
      this.bridgeData = this.bridgeData.map((bridge) => ({
        ...bridge,
        distance: bridge.distance / 1000,
        code_road: bridge.code_road ?? '99'
      }))
      this.computeVirtualScrollerHeight()
      this.loading = false
      this.$refs.searchBridge.closeSuggestMenu()
      this.filter = {}
    },
    async getSectionPart (roadid) {
      this.controlList = await this.$store.dispatch('getSectionPart', {
        roadid
      })
    },
    async getRefBridgeCategory () {
      const data = await this.$store.dispatch('ref/getRef', {
        method: 'bridge_category'
      })
      data.forEach((item) => {
        const icon =
          item.id === '1'
            ? '$NormalBridgeIcon'
            : item.id === '2'
              ? '$CrossBridgeIcon'
              : item.id === '3'
                ? '$OverpassBridgeIcon'
                : '$InterChangeBridgeIcon'
        this.legendItems.push({
          ...item,
          icon
        })
      })
    },
    clearFilter () {
      this.filter = {
        control: null,
        road: '',
        km_start: '',
        km_end: '',
        type: ''
      }
    },
    boundMap () {
      if (
        this.map &&
        Array.isArray(this.bridgeData) &&
        this.bridgeData.length
      ) {
        const allLocation = []
        this.bridgeData.forEach((bridge) => {
          allLocation.push(this.markerLocation(bridge))
        })
        const bound = window.longdo.Util.locationBound(allLocation)
        this.map.bound(bound)
        this.map.resize()
      }
    },

    inputSuggest (keyword) {
      this.keyword = keyword
      this.getBridgeList()
    },
    checkBridgeType (type) {
      if (type === '1') {
        return this.icon.normal
      } else if (type === '2') {
        return this.icon.crossBridge
      } else if (type === '3') {
        return this.icon.overpass
      } else if (type === '4') {
        return this.icon.interChange
      }
    },
    async getRefFilterSr () {
      this.itemLists.sr = await this.$store.dispatch('getRefFilterSr')
    },
    async getSettingSr () {
      const srData = await this.$store.dispatch('setting/getSettingSr')
      srData.forEach((sr) => {
        sr.color =
          sr.id === '1'
            ? this.colors[0]
            : sr.id === '2'
              ? this.colors[1]
              : this.colors[2]
      })
      this.srData = srData
    },
    getColorIcon (sr) {
      if (!sr) {
        return this.colors[3]
      }
      if (sr < parseInt(this.srData[0].max) * 10) {
        return this.srData[0].color
      } else if (sr < parseInt(this.srData[1].max) * 10) {
        return this.srData[1].color
      } else if (sr <= parseInt(this.srData[2].max) * 10) {
        return this.srData[2].color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.split {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.locationLoading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  content: '\2026';
  animation: ellipsis steps(4, end) 4s infinite;
  width: 0;
}
@keyframes ellipsis {
  to {
    width: 0.8rem;
  }
}
@media screen and (min-width: 600px) {
  #legendMap {
    position: fixed;
    bottom: 40px;
    right: 5px;
    z-index: 2;
    width: 250px;
  }
}
@media screen and (max-width: 600px) {
  #legendMap {
    position: fixed;
    top: 90px;
    right: 5px;
    z-index: 2;
  }
  .v-expansion-panel-header {
    padding: 10px 16px;
  }
}
</style>
